<template>
  <base-filter-button
    :badge="badge"
    @reset="reset"
    @submit="submit"
    @after-hide="afterHide"
  >
    <div class="mb-2 p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.playerLevel') }}
      </h4>
      <base-rank-select v-model="state.ranks" />
    </div>
    <div class="mb-2 p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.playerStatus') }}
      </h4>
      <base-status-select
        v-model="state.status"
        :options="statusOptions"
      />
    </div>
    <div class="mb-2 p-4 bg-white">
      <h4 class="m-0 mb-15px">
        {{ t('common.isDeposit') }}
      </h4>
      <base-status-select
        v-model="state.is_deposit"
        :options="isDepositOptions"
      />
    </div>
    <div class="p-4 bg-white">
      <div class="flex-row-between">
        <h4 class="m-0 mb-15px">
          {{ t('common.notLoginDays') }}
        </h4>
        <span class="text-3.5">{{ notLoginDaysValue }}</span>
      </div>
      <prime-slider
        v-model="state.not_login_days"
        :min="0"
        :max="30"
      />
      <div class="flex-row-between mt-4 text-3.5">
        <span>{{ t('common.noLimit') }}{{ t('common.overDays', { day: 1 }) }}</span>
        <span>{{ t('common.overDays', { day: 15 }) }}</span>
        <span>{{ t('common.overDays', { day: 30 }) }}</span>
      </div>
    </div>
  </base-filter-button>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { PlayerRankType, PlayerStatusType } from '@golden/gdk-agent-next'

export default defineComponent({
  name: 'PlayersFilterButton',
  props: {
    modelValue: {
      type: Object as PropType<{ ranks?: PlayerRankType[], status?: PlayerStatusType[], is_deposit?: Array<0 | 1>, not_login_days?: number }>,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup (props, context) {
    const { t } = useI18n()
    const state = reactive({ ...props.modelValue })

    const statusOptions = ref([
      {
        name: t('userStatus.noBindCard'),
        value: PlayerStatusType.NO_CARD
      },
      {
        name: t('userStatus.normal'),
        value: PlayerStatusType.NORMAL
      },
      {
        name: t('userStatus.suspended'),
        value: PlayerStatusType.SUSPENDED
      }
    ])
    const isDepositOptions = [
      {
        name: t('common.yes'),
        value: 1
      },
      {
        name: t('common.no'),
        value: 0
      }
    ]

    const submit = () => {
      context.emit('update:modelValue', state)
    }
    const reset = () => {
      context.emit('update:modelValue', { ranks: undefined, status: undefined, is_deposit: undefined, not_login_days: undefined })
    }

    const notLoginDaysValue = computed(() => {
      if (!state.not_login_days) return t('common.noLimit')
      return t('common.overDays', { day: state.not_login_days })
    })

    const afterHide = () => {
      state.ranks = props.modelValue.ranks
      state.status = props.modelValue.status
      state.is_deposit = props.modelValue.is_deposit
      state.not_login_days = props.modelValue.not_login_days
    }

    const badge = computed(() => {
      return Object.values(props.modelValue).reduce<number>((accu, curr) => {
        if (curr === undefined) return accu
        if (Array.isArray(curr)) return accu + curr.length
        return accu + 1
      }, 0)
    })

    return {
      t,
      state,
      statusOptions,
      isDepositOptions,
      notLoginDaysValue,
      badge,
      reset,
      submit,
      afterHide
    }
  }
})
</script>
