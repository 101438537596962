<template>
  <aside
    id="desktop-sidebar"
  >
    <div class="logo">
      <img src="@/assets/images/icon.png">
      <span class="text-18px fw-700 color-#335181">{{ t('common.goldenAgent') }}</span>
    </div>
    <prime-panel-menu
      v-model:expanded-keys="expandedKeys"
      :model="nav"
      class="flex-1 w-full"
    >
      <template #item="{item}">
        <router-link
          v-slot="{ navigate, isActive }"
          custom
          :to="{ name: item.routeName }"
        >
          <a
            class="color-[var(--text-color)] text-4 decoration-none px-5 h-50px flex-row-start relative"
            :class="{ 'p-menuitem-link': !item.items, 'router-link-active': isActive }"
            @click="() => click(navigate, !!item.items || item.routeName === route.name)"
          >
            <span
              v-if="item.items"
              class="p-submenu-icon i-ri-arrow-right-s-line mr-2"
              :class="{ 'rotate-90': expandedKeys[item.routeName ?? ''] }"
            />
            <i
              class="p-menuitem-icon text-6 w-1.5rem mr-2"
              :class="item.icon"
            />
            <i
              class="p-menuitem-icon text-6 w-1.5rem mr-2 hidden"
              :class="item.activeIcon"
            />
            <span class="p-menuitem-text">
              {{ item.label }}
            </span>
            <prime-badge
              v-if="item.unread.value && (!item.items || !expandedKeys[item.routeName ?? ''])"
              value="new"
              class="absolute right-0 mr-5"
            />
          </a>
        </router-link>
      </template>
    </prime-panel-menu>
    <a
      class="customer color-[var(--text-color)] text-4 decoration-none px-5 h-50px flex-row-start p-menuitem-link w-full cursor-pointer"
      @click="openCustomer"
    >
      <i class="mr-2 w-4 i-ri-customer-service-line" />
      <i class="hidden mr-2 w-4 i-ri-customer-service-fill" />
      <span class="p-menuitem-text">
        {{ t('common.contactCustomer') }}
      </span>
    </a>
  </aside>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useNav } from '@/functions/useNav'
import { useI18n } from 'vue-i18n'
import { useAgentStore } from '@/stores/agent'
import { storeToRefs } from 'pinia'
import { RouteNameType } from '@/constants/routeNameMapPermission'
import { useCustomer } from '@/functions/useCustomer'

export default defineComponent({
  name: 'DesktopSideBar',
  setup () {
    const route = useRoute()
    const { t } = useI18n()
    const { openCustomer } = useCustomer()

    const navConfigs = computed(() => [
      {
        label: t('page.home'),
        routeName: RouteNameType.HOME,
        key: 'home',
        icon: 'i-ri-home-4-line',
        activeIcon: 'i-ri-home-4-fill'
      },
      {
        label: t('page.users'),
        routeName: RouteNameType.USERS,
        icon: 'i-ri-group-line',
        activeIcon: 'i-ri-group-fill',
        items: [
          {
            icon: '',
            label: t('page.players'),
            routeName: RouteNameType.PLAYERS
          },
          {
            icon: '',
            label: t('page.agents'),
            routeName: RouteNameType.AGENTS
          },
          {
            icon: '',
            label: t('page.sub-account'),
            routeName: RouteNameType.SUB_ACCOUNT
          }
        ]
      },
      {
        label: t('page.promote'),
        routeName: RouteNameType.PROMOTE,
        icon: 'i-ri-share-line',
        activeIcon: 'i-ri-share-fill',
        items: [
          {
            icon: '',
            label: t('page.promote-url'),
            routeName: RouteNameType.PROMOTE_URL,
            unread: computed(() => !me.value?.is_domain_read && route.name !== 'promote-url' ? 1 : 0)
          },
          {
            icon: '',
            label: t('page.promote-activities'),
            routeName: RouteNameType.PROMOTE_ACTIVITIES
          }
        ]
      },
      {
        label: t('page.report'),
        routeName: RouteNameType.REPORT,
        icon: 'i-ri-table-line',
        activeIcon: 'i-ri-table-fill',
        items: [
          {
            icon: '',
            label: t('page.commission'),
            routeName: RouteNameType.COMMISSION
          },
          {
            icon: '',
            label: t('page.bet'),
            routeName: RouteNameType.BET
          },
          {
            icon: '',
            label: t('page.transaction'),
            routeName: RouteNameType.TRANSACTION
          },
          {
            icon: '',
            label: t('page.activity'),
            routeName: RouteNameType.ACTIVITY
          }
        ]
      }
    ])
    const { nav } = useNav(navConfigs)
    const { me } = storeToRefs(useAgentStore())

    const expandedKeys = ref<Record<string, true>>({})
    watchEffect(() => {
      expandedKeys.value[route.matched[0]?.name as string] = true
    })

    const click = (navigate: () => void, disabled: boolean) => {
      if (disabled) return
      navigate()
    }

    return {
      t,
      nav,
      expandedKeys,
      route,
      click,
      openCustomer
    }
  }
})
</script>

<style lang="scss" scoped>
#desktop-sidebar {
  @apply hidden md:flex-col-start basis-200px shrink-0 bg-transparent border-[var(--gray-300)] border-r-solid border-r;
  .logo {
    @apply flex-row-start h-[var(--mobile-toolbar-height)] border-[var(--gray-300)] border-b-solid border-b w-full px-5;
    img {
      @apply w-24px mr-6px;
    }
  }
  :deep(.p-panelmenu-panel) {
    @apply mb-0;
  }

  :deep(.p-panelmenu-content) {
    @apply border-0 p-0 bg-transparent;
  }

  :deep(.p-submenu-icon) {
    @apply absolute right-10px text-4;
  }

  :deep(.p-panelmenu-header-content) {
    @apply bg-transparent border-0;
  }
  :deep(.p-panelmenu-header) {
    @apply bg-transparent border-0 cursor-pointer;

    .p-panelmenu-header-content .p-menuitem-link.router-link-active {
      .p-menuitem-text, .p-menuitem-icon {
        @apply color-[var(--primary-color)];
      }
    }
  }
  :deep(.p-panelmenu-header-content) {
    &:hover .p-menuitem-icon, &:active .p-menuitem-icon, .router-link-active .p-menuitem-icon {
      @apply first-of-type:hidden inline-block;
    }
  }
  :deep(.p-menuitem-content .p-menuitem-link) {
    .p-menuitem-text {
      @apply color-[var(--text-color)];
    }

    &.router-link-active .p-menuitem-text {
      @apply color-[var(--primary-color)];
    }
  }
  :deep(.p-menuitem-content) {
    @apply important-bg-transparent;
  }
  :deep(.p-panelmenu .p-panelmenu-header:not(.p-disabled):focus .p-panelmenu-header-content) {
    box-shadow: none;
  }
}

.customer {
  &:hover i, &:active i {
    @apply first-of-type:hidden inline-block;
  }
}
</style>
