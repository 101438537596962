import { Ref, watch, ref } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import thisVersion, { getLatestVersionParams } from 'virtual:golden/version'
import { checkUpdateWhenNoSW } from '@golden/app-plugins'
import gdk from '@/gdk'
import router from '@/router'

const intervalMS = 1 * 60 * 1000
function useOldUpdateVersion (): { isReload: Ref<boolean>, reload: () => void, close: () => void } {
  const isReload = ref(false)

  const subscription = checkUpdateWhenNoSW(
    gdk,
    getLatestVersionParams(import.meta.env.BASE_URL),
    thisVersion
  ).subscribe((data) => { isReload.value = !data })

  const reload = () => {
    window.location.reload()
    isReload.value = false
  }

  const close = () => {
    if (!subscription.closed) subscription.unsubscribe()
  }

  return { isReload, reload, close }
}

export function useUpdateVersion () {
  const { close: closeUpdate, reload: reloadWindow, isReload } = useOldUpdateVersion()

  const isUpdateSW = ref(false)

  const {
    updateServiceWorker,
    offlineReady
  } = useRegisterSW({
    onOfflineReady () {
      // eslint-disable-next-line no-console
      console.log('onOfflineReady')

      closeUpdate()
    },
    onRegistered (r) {
      if (!r) return
      // eslint-disable-next-line no-console
      console.log('onRegistered')

      void r.update()
      setInterval(() => { void r.update() }, intervalMS)
    },
    onNeedRefresh () {
      console.log('onNeedRefresh')

      isUpdateSW.value = true
    }
  })

  router.afterEach(() => {
    if (isUpdateSW.value) {
      void updateServiceWorker(true)
      isUpdateSW.value = false
    }
    isReload.value && reloadWindow()
  })

  watch(offlineReady, (value) => {
    if (!value) return

    // eslint-disable-next-line no-console
    console.log('App ready to work offline')
  })
}
